import React from "react";

export const Cause = (props) => {
  return (
    <div id="cause" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Soutenez nos causes</h2>
          <p>
            Faites une action pour contribuer à l'épanouissement d'une personne dans le besoin.
          </p>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
              <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 cause">
                <div className="thumbnail">
                  {" "}
                  <img src={d.img} alt="..." className="cause-img" />
                  <div className="caption">
                    <h4>{d.name}</h4>
                    <p>{d.job}</p>
                    <a
                      href="#features"
                      className="btn btn-custom btn-lg page-scroll"
                    >
                      Faire un don
                    </a>{" "}
                  </div>
                </div>
              </div>
            ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
